<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app>
            <v-toolbar-title class="text-overline" style="font-size: 16px!important;">
                <router-link :to="{ name: 'front' }" style="text-decoration: none;" v-if="!isAuthenticated">
                    <span class="purple--text text--darken-1">Liberty</span><span class="purple--text text--lighten-2">Base</span>
                    <!-- <v-img alt="Unicorn Springs" src="@/assets/logo/unicornsprings/UnicornSprings_dark_gold_green_348x64.png" class="mr-1 float-left" width="196" height="36" fit="inside"></v-img> -->
                </router-link>
                <router-link :to="{ name: 'dashboard' }" style="text-decoration: none" v-if="isAuthenticated">
                    <span class="purple--text text--darken-1">Liberty</span><span class="purple--text text--lighten-2">Base</span>
                </router-link>
            </v-toolbar-title>
            <!-- 240x48, 180x36, 120x24, 90x18 -->
            <!-- <router-link to="/">
                <v-img
                alt="Webauthz"
                class="shrink mr-2"
                contain
                :src="require('@/assets/logo/Webauthz_nobg_240x48.png')"
                transition="scale-transition"
                width="120"
                height="24"
                />
                </router-link> -->
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="purple"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <div v-if="!isAuthenticated">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="purple" right>
                        <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                        <!-- <v-tab to="/about">About</v-tab>
                        <v-tab to="/login">Login</v-tab> -->
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on">
                                <font-awesome-icon right class="purple--text" icon="bars" fixed-width/>
                            </v-btn>
                        </template>
                        <v-tabs vertical slider-color="purple">
                            <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                            <!-- <v-tab to="/about">About</v-tab>
                            <v-tab to="/login">Login</v-tab> -->
                        </v-tabs >
                    </v-menu>
                </div>
            </div>
            <div v-if="isAuthenticated">
                <v-tabs slider-color="purple">
                    <!-- Show toolbar buttons on large screens -->
                    <v-tab :to="{ name: 'dashboard' }" v-show="false"></v-tab>
                    <!-- <v-tab to='/user' v-show="$vuetify.breakpoint.smAndUp">Users</v-tab>
                    <v-tab to='/group' v-show="$vuetify.breakpoint.smAndUp">Groups</v-tab>
                    <v-tab to='/access-policy' v-show="$vuetify.breakpoint.smAndUp">Access</v-tab> -->
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-tab v-on="on" class="purple--text">
                                <Avatar :attr="mainProfile" :size="36"/>
                                <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                            </v-tab>
                        </template>
                        <v-list class="ma-0 pa-0">
                            <div>
                                <v-tabs vertical slider-color="purple" class="menutabs">
                                    <v-tab :to="{ name: 'dashboard' }" v-show="false"></v-tab>
                                    <!-- Show these in menu if screen is too small to fit buttons -->
                                    <!-- <v-tab to='/user' v-show="$vuetify.breakpoint.xsOnly">Users</v-tab>
                                    <v-tab to='/group' v-show="$vuetify.breakpoint.xsOnly">Groups</v-tab>
                                    <v-tab to='/access-policy' v-show="$vuetify.breakpoint.xsOnly">Access</v-tab> -->
                                    <!-- Show these in menu always -->
                                    <v-tab :to="{ name: 'profile' }">Profile</v-tab>
                                    <v-tab :to="{ name: 'dashboard' }">Dashboard</v-tab>
                                    <!-- <v-tab to='/account'>Accounts</v-tab> -->
                                    <!-- <v-tab to="/about">About</v-tab> -->
                                    <!-- <v-tab :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">Service admin</v-tab> -->
                                    <v-divider></v-divider>
                                    <v-tab @click="onLogout">Logout</v-tab>
                                </v-tabs>
                            </div>
                        </v-list>
                    </v-menu>
                </v-tabs>
            </div>
        </v-app-bar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';

export default {
    components: {
        Avatar,
    },
    methods: {
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push({ name: 'front' });
            }
        },
    },

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
        // isPermitServiceAdmin() {
        //     return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        // },
    },

    mounted() {
        console.log(`navbar route params ${JSON.stringify(this.$route.params)}`);
        if (this.$route.params.realmId) {
            console.log(`navabar realm ${this.$route.params.realmId}`);
        } else {
            console.log('navbar no realm');
        }
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
